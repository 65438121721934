<template>
  <!-- Error page-->
  <b-card>
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-200 text-center">

        <b-img
          fluid
          :src="imgUrl"
          alt="Unauthorized page"
        /> 
        <h2 class="mt-4">
          {{$t('Δεν επιτρέπεται η πρόσβαση.')}} 
        </h2>
        <p>
          {{$t('Δυστυχώς, δεν δικαιούσαι πρόσβαση στη σελίδα που ψάχνεις.')}} 
        </p>

        <b-button
          variant="primary"
          class="mt-2 btn-sm-block"
          :to="{path:'/'}"
        >
          {{$t('Επιστροφή στην Αρχική')}} 
        </b-button>

        
      </div>
    </div>
  </div>
  </b-card>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg, BCard } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BCard
  },
  data() {
    return {
      imgUrl: require('/var/www/restock-supplier-interface/src/assets/custom/unauthorized.svg'),
      logoUrl: require('/var/www/restock-supplier-interface/public/logo.png'),
    }
  },
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
